import { format, getWeek } from "date-fns";
import { type CalculateRotationWeekNumberArgs } from "@/types/Calendar";

export const DAYS_IN_WEEK = 7;

export enum DayType {
    Onboard = "onboard_day",
    Travel = "travel_day",
    Change = "change_day",
    TravelAndChange = "travel_day_change_day",
    RotationalLeave = "rotational_leave",
    Training = "training_day",
    PaidOut = "paid_out_day",
    CompassionateLeave = "compassionate_leave",
    End = "end_day",
    MedicalLeave = "medical_leave",
    Other = "other_day",
	Empty = "empty_day"
}

export function getClassFromType(type: string) {
	switch (type) {
		case DayType.Onboard:
			// return "bg-blue-200";
			return "bg-[#8ED873]"
		case DayType.Travel:
			// return "bg-gray-300";
			return "bg-[#F6C6AC]";
		case DayType.Change:
		case DayType.TravelAndChange:
			// return "bg-green-300";
			return "bg-[#F2FF92]";
		case DayType.RotationalLeave:
			// return "bg-pink-400";
			return "bg-[#FF7E79]";
		case "undefined":
		case DayType.Empty:
			return "";
		default:
			return "bg-yellow-200";
	}
}

export function getDayType(type: string) {
    switch (type) {
        case DayType.Onboard:
            return "Onboard Day";
        case DayType.Travel:
            return "Travel Day";
        case DayType.Change:
            return "Change Day";
        case DayType.RotationalLeave:
            return "Rotational Leave";
        case DayType.Training:
            return "Training Day";
        case DayType.PaidOut:
            return "Paid Out Day";
        case DayType.CompassionateLeave:
            return "Compassionate Leave";
        case DayType.End:
            return "End Day";
        case DayType.MedicalLeave:
            return "Medical Leave";
		case DayType.TravelAndChange:
			return "Travel Day / Change Day";
        default:
            return "";
    };
}

export function getDayNameFromDate(date: string | Date) {
	const formattedDate = new Date(date);
	return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(formattedDate);
}

export function getMonthNameFromDate(date: string | Date) {
	const formattedDate = new Date(date);
	return new Intl.DateTimeFormat("en-US", { month: "short" }).format(formattedDate);
}

export const legend = [
	{ type: DayType.Onboard, name: "Onboard", color: "bg-[#8ED873]" },
	{ type: DayType.Travel, name: "Travel", color: "bg-[#F6C6AC]" },
	{ type: DayType.Change, name: "Change", color: "bg-[#F2FF92]" },
	{ type: DayType.RotationalLeave, name: "Rotational Leave", color: "bg-[#FF7E79]" },
	{ type: DayType.Other, name: "Other day", color: "bg-yellow-200"},
]

export function prepareDate(date: string, type: ('start_date' | 'end_date')) {
    if(!date) throw new Error("Date is not defined");
    const dateObj = new Date(date);
    const moveByDays = type === 'start_date' ? 0 : 0;
    dateObj.setDate(dateObj.getDate() + moveByDays);
    return format(dateObj, "yyyy-MM-dd");
}

export function getEmptyDaysCount(firstDateInData: number){
    if(firstDateInData === null || firstDateInData === undefined) throw new Error("First date in data is not defined");
    if (firstDateInData === 0) {
        return 6;
    }
    return firstDateInData - 1;
}

export function getEmptyFirstStartDate(nextDate: string, days: number){
    if(!nextDate || !days) return new Date();
    const date = new Date(nextDate);
    date.setDate(date.getDate() - days);
    return date;
}

export function calculateRotationWeekNumber({ rotation, page, data, rotationsDatesRanges, isPartner=false, rotationPeriodType }: CalculateRotationWeekNumberArgs): (string | undefined)[] {
	if(!rotationPeriodType) return [];
    let restWeekNumber = 0;
	let onboardWeekNumber = 0;
	let changeDay = '';

	if( rotationPeriodType === 'months' ) {
		restWeekNumber = 1;
		onboardWeekNumber = 1;
	}

	function determineDayTypeForWeeks (weekType: string[], index: number) {
		// if page is first then return undefined for first week
		if(index === 0 && ((page ? page : 1) === 1)) {
			return 'undefined';
		} else if(index === 0 && ((page ? page : 1) !== 1)) {
			if(weekType[0] === 'onboard') {
				return 'offboard';
			}
			return 'onboard';
		}
		
		return weekType[0];
	}

	function determineDayTypeForMonths (weekType: string[], index: number) {
		if(index === 0 && ((page ? page : 1) === 1)) {
			return weekType[0];
		} else if(index === 0 && ((page ? page : 1) !== 1)) {
			if(weekType[0] === 'onboard') {
				return 'offboard';
			}
			return 'onboard';
		}
		
		return weekType[0];
	}

	function prepareTextForWeeks (weekType: string, index: number, arr: string[]) {
		// add week number to week type
		if(!rotation) return;
		const [onboardWeeks, offBoardWeeks] = rotation.split('/');
		const currentWeekType = arr[index];

		if(currentWeekType === "onboard") {
			onboardWeekNumber++;
			restWeekNumber = 0;
		} else if(currentWeekType === "offboard") {
			restWeekNumber++;
			onboardWeekNumber = 0;
		}

		let isFirst = false;
		let isSame = false;

		if(index === 0) {
			isFirst = true;
			if(arr[index + 1] === currentWeekType) {
				isSame = true;
			}
		}

		if(weekType === "onboard") {
			if(isFirst) {
				if(isSame) {
					return `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
				}
				return `Onboard (${onboardWeeks}/${onboardWeeks})`;
			}
			return `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
		} else if(weekType === "offboard") {
			if(isFirst) {
				if(isSame) {
					return `Offboard (${restWeekNumber}/${offBoardWeeks})`;
				}
				return `Offboard (${offBoardWeeks}/${offBoardWeeks})`;
			}
			return `Offboard (${restWeekNumber}/${offBoardWeeks})`;
		} else {
			return '';
		}
	}

	function prepareTextForMonths (weekType: string, index: number, arr: string[]) {
		if(!rotation) return;
		const [onboardWeeks, offBoardWeeks] = rotation.split('/');

		const currentWeekType = arr[index];

		let isFirst = false;
		let isSame = false;

		if(index === 0) {
			isFirst = true;
			if(arr[index + 1] === currentWeekType) {
				isSame = true;
			}
		}

		let text = '';

		if(weekType === "onboard") {
			if(isFirst) {
				if(isSame) {
					text = `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
				}
				text = `Onboard (${onboardWeeks}/${onboardWeeks})`;
			}
			text = `Onboard (${onboardWeekNumber}/${onboardWeeks})`;
		} else if(weekType === "offboard") {
			if(isFirst) {
				if(isSame) {
					text = `Offboard (${restWeekNumber}/${offBoardWeeks})`;
				}
				text = `Offboard (${offBoardWeeks}/${offBoardWeeks})`;
			}
			text = `Offboard (${restWeekNumber}/${offBoardWeeks})`;
		} else {
			text = '';
		}

		if(currentWeekType === "onboard") {
			if(determineWhichWeekIsIncrement[index].includes('increment') && !isFirst) {
				onboardWeekNumber++;
			}
			restWeekNumber = 1;
		} else if(currentWeekType === "offboard") {
			if(determineWhichWeekIsIncrement[index].includes('increment') && !isFirst) {
				restWeekNumber++;
			}
			onboardWeekNumber = 1;
		}

		return text;
	}

	const periodTypesArray = data
	.map((d) => {
		// Generate array of arrays with periodType and date ie.: [["onboard_day", "2022-01-01"], ["change_day", "2022-01-02"], ...]
		const elements = [];

		const { type: periodType, duration: periodDuration } = d;

		for (let index = 0; index < periodDuration; index++) {
			const date = new Date(d.start_date);
			date.setDate(date.getDate() + index);

			elements.push([periodType, format(date, "yyyy-MM-dd")]);
		}
		return elements;
	})
	.flat()
	.reduce((acc: string[][][], curr, index) => {
		// Divide data into weeks
		const arrIndex = Math.floor(index / 7);

		if(index % 7 === 0) {
			acc.push([curr]);
		} else {
			acc[arrIndex].push(curr);
		}

		return acc;
	}, []);

	if(rotationPeriodType === 'leave_allowance') {
		const weeksNumber = periodTypesArray.map((week: string[][]) => {
			const firstDateOfWeek = week[0][1];
			const date = new Date(firstDateOfWeek);
	
			const weekNumber = getWeek(date);
			return String(weekNumber);
		});
		return weeksNumber;
	}

	const determineWhichWeekIsIncrement = data.map((d) => {
		// Generate array of arrays with periodType and date ie.: [["onboard_day", "2022-01-01"], ["change_day", "2022-01-02"], ...]
		const elements = [];

		const { type: periodType, duration: periodDuration } = d;

		for (let index = 0; index < periodDuration; index++) {
			const date = new Date(d.start_date);
			date.setDate(date.getDate() + index);

			elements.push([periodType, format(date, "yyyy-MM-dd")]);
		}
		return elements;
	}).flat().reduce((acc: string[][][], curr, index) => {
		// Divide data into weeks
		const arrIndex = Math.floor(index / 7);

		if(index % 7 === 0) {
			acc.push([curr]);
		} else {
			acc[arrIndex].push(curr);
		}

		return acc;
	}, []).map((days: string[][]) => {
		const weekType: string[] = [];

		days.forEach((day) => {
			const [dayType, date] = day;
			
			if(dayType === 'change_day' || dayType === 'travel_day_change_day') {
				changeDay = date;
			}

			const dayDate = new Date(date).getDate();

			let changeDayDate = -1;
			if(changeDay) {
				const selectedDateMonth = new Date(date).getMonth();
				const selectedDateYear = new Date(date).getFullYear();
				const selectedDateLastDay = new Date(selectedDateYear, selectedDateMonth + 1, 0).getDate();
				const _changeDayDate = new Date(changeDay).getDate();

				// if changeDay is bigger then current month days count then set changeDayDate to last day of month
				if(_changeDayDate > selectedDateLastDay) {
					changeDayDate = selectedDateLastDay;
				} else {
					changeDayDate = _changeDayDate;
				}
			}

			if(dayDate === changeDayDate) {
				weekType.push('increment');
			} else {
				weekType.push('');
			}
		})

		const weekTypeSet = new Set(weekType);
		// weekType will be ie: 
		// 1.['', 'increment']
		// 2.['']

		return Array.from(weekTypeSet);
	});

	const determineDayType = periodTypesArray.map((days: string[][]) => {
		// Check if day is withing range in onboard or offboard
		const weekType: string[] = [];

		days.forEach((day) => {
			const [_dayType, date] = day;
			if(!rotationsDatesRanges) return 'undefined';

			rotationsDatesRanges.forEach((rotation) => {
				const { onboard, offboard } = rotation;

				if(!offboard || !onboard) return;

				const onboardEndDate = new Date(onboard.end_date);
				const offboardEndDate = new Date(offboard.end_date);

				// IMPORTANT! Adding + 1 to end date of onboard and offboard to include last day, 
				// remove it if onboard.end_date === offboard.start_date and offboard.end_date === onboard.start_date
				onboardEndDate.setHours(12);
				offboardEndDate.setHours(12);
				onboardEndDate.setDate(onboardEndDate.getDate() + 1);
				offboardEndDate.setDate(offboardEndDate.getDate() + 1);

				const isWithinOffboardRange = date >= offboard.start_date && date <= format(offboardEndDate, 'yyyy-MM-dd');
				const isWithinOnboardRange = date >= onboard.start_date && date <= format(onboardEndDate, 'yyyy-MM-dd');
				
				if(isPartner) {
					if(isWithinOffboardRange) {
						weekType.push('offboard');
					}
					if(isWithinOnboardRange) {
						weekType.push('onboard'); 
					}
				} else {
					if(isWithinOnboardRange) {
						weekType.push('onboard'); 
					}
					if(isWithinOffboardRange) {
						weekType.push('offboard');
					}
				}
			});
		})
		// weekType will be ie: 
		// 1.['onboard', 'onboard', 'onboard', 'onboard', 'onboard', 'onboard', 'onboard']
		// 2.['onboard', 'onboard', 'onboard', 'offboard', 'offboard', 'offboard', 'offboard', 'offboard']

		const weekTypeSet = new Set(weekType);
		// weekTypeSet will be ie:
		// 1.['onboard']
		// 2.['onboard', 'offboard']

		return Array.from(weekTypeSet);
	})

	const formattedRotationNumber = determineDayType
	.map( rotationPeriodType === 'months' ? determineDayTypeForMonths : determineDayTypeForWeeks )
	.map( rotationPeriodType === 'months' ? prepareTextForMonths : prepareTextForWeeks);

	return formattedRotationNumber;
}
