// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Tooltip from '@radix-ui/react-tooltip';
import { format } from "date-fns";
import { getClassFromType } from "@/utils/calendarUtils";
import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";

import { MonthChange } from '../calendar/Cell/MonthChange';

interface FormattedDocuments {
	[key: string]: {
		title: string;
		id: string | number;
	};
}

type Props = {
	documents: FormattedDocuments[] | undefined | object;
	type: string; 
	date: Date; 
	weekYear: number; 
	clickHandler: ({startDate, dayKind}:{startDate: Date, dayKind: string}) => void;
	onClickDocument: (id: string | number) => void;
	className?: string;
};

export const Cell = ({ className="", onClickDocument, documents, type, date, clickHandler }: Props) => {
	const { data: configData } = useGetAppConfigQuery({});
	const formattedDate = date ? format(date, "y-MM-dd") : "-";

	const day = date.toLocaleString("default", { day: "numeric" });

	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>
					<div className={`ctrl__cell-day grow relative cursor-pointer flex items-center justify-center rounded-md border border-gray-300 font-medium text-xs lg:text-sm ${getClassFromType(type)} ${className}`}
						onClick={() => clickHandler({startDate: date, dayKind: type})}
					>
						<div className="relative w-full h-full flex justify-center items-center font-medium overflow-hidden rounded-md">
							{day}

							{ (documents && documents[formattedDate]) && (
								<div className="top-0 left-0 right-0 absolute h-2 bg-red-500 rotate-[45deg] translate-x-2/4"></div>
							)}
						</div>

						<MonthChange day={day}/>
					</div>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content className="TooltipContent" sideOffset={5}>

						<div className="flex flex-col">
							<div className="text-xs lg:text-sm font-medium capitalize">{ configData?.data?.day_types?.[type] }</div>
							<div className="text-xs lg:text-sm">{ formattedDate }</div>

							{ (documents && documents[formattedDate]) && (
								<div className="mt-2">
									<div className="text-xs lg:text-sm font-medium capitalize">Related documents: </div>
							
									{documents[formattedDate].map((doc: {id: string | number, title: string}) => {
										return (
											<div key={doc.id}>
												{/* <a href={doc.url} target="_blank" rel="noreferrer" className="text-xs lg:text-sm text-primary underline">{doc.name}</a> */}
												<p onClick={() => onClickDocument(doc.id)} className="text-xs lg:text-sm text-primary underline cursor-pointer">{doc.title}</p>
											</div>
										);
									})}
							
								</div>
							) }
						</div>

					</Tooltip.Content>
				</Tooltip.Portal>
      		</Tooltip.Root>
    	</Tooltip.Provider>
	);
};
