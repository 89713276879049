/* eslint-disable @typescript-eslint/no-explicit-any */

import { addDays, getWeek, format } from "date-fns";
import { Cell } from "./Cell";
import { Collapsible } from "./Collapsible";
import { LabelCells } from "./LabelCells";
import { cn } from "@/lib/utils";
import { DAYS_IN_WEEK, calculateRotationWeekNumber } from "@/utils/calendarUtils";
import { type RotationDaysInterface, type RotationDatesInfoInterface, type DatesObjectInterface, type CrewMemberInterface} from "@/types/Calendar";
import { type DayKindDialogHandler } from "@/components/calendar/DayKindDialog/types";


interface FormattedDocuments {
	[key: string]: {
		title: string;
		id: string | number;
	}[];
}

type Props = { 
	page: number | undefined,
	rotationsDatesRanges: undefined | { id: number, name: string, rotations: RotationDaysInterface[]}[],
	hoveredCells: string[] | undefined,
	setHoveredCell: (cell: string | undefined) => void,
	changeDayStartDate: string | undefined,
	endDate: string | undefined,
	endDateHandler: (date:string) => void,
	crewData: { id: number, name: string }[] | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[], 
	showDayKindModalHandler: DayKindDialogHandler, 
	rotation: string | undefined | null,
	rotationsInfo: {
		[key: number]: {onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}
	} | undefined,
	openDocumentModal: (id: string | number) => void,
	crewDocuments: CrewMemberInterface[] | undefined,
	rotationPeriodType: string | undefined,
	cellColor: string
};

export const generateCells = ({
		page, 
		rotationsDatesRanges, 
		hoveredCells,
		setHoveredCell, 
		changeDayStartDate, 
		endDate, 
		endDateHandler, 
		crewData, 
		data, 
		showDayKindModalHandler, 
		rotation, 
		rotationsInfo,
		crewDocuments,
		openDocumentModal,
		rotationPeriodType,
		cellColor
} : Props
) => {
	if(!data || data.length === 0 || !rotationsInfo) return null;

	const firstStartDate = data[0]?.[0]?.start_date;
	if(!firstStartDate) return null;

	const dayClickHandler: DayKindDialogHandler = ({startDate, crewData}) => {
		if(changeDayStartDate) {
			endDateHandler(format(startDate, "yyyy-MM-dd"));
			return;
		} 
		showDayKindModalHandler({startDate, crewData});
	}

	let daysGenerated = 0;

	const datesObject: DatesObjectInterface = {};
	data?.forEach( (calendarData) => {

		calendarData.forEach((d: {crew_member_id: number, crew_member_name: string, rotation_id: number, type: string, start_date: Date, duration: number}) => {
			const formattedDate = new Date(d.start_date);
			const { duration: periodDuration } = d;

			for (let index = 0; index < periodDuration; index++) {

				const cellDate = addDays(formattedDate, index);
				const dateKey = format(cellDate, "y-MM-dd");

				if(!datesObject[dateKey]) {
					datesObject[dateKey] = [];
				}

				const dayData = {
					crew_id: d.crew_member_id,
					crew_name: d.crew_member_name,
					rotation_id: d.rotation_id,
					type: d.type,
				}
				datesObject[dateKey].push(dayData);
			}
		});
	});

	const formattedDocuments: { [key: string | number]: FormattedDocuments } | undefined = crewDocuments?.reduce((acc, doc) => {
		const userId = doc.id;
		if(!doc.documents) return acc;
		const documents = doc.documents.reduce((acc, doc) => {
			const startDate = doc.start_date;
			const endDate = doc.end_date || startDate;
			function dateRange({startDate, endDate}: {startDate: string, endDate: string}) {
				const dateArray = [];
				const currentDate = new Date(startDate);
			  
				while (currentDate <= new Date(endDate)) {
				  dateArray.push(format(currentDate, "y-MM-dd"));
				  // Use UTC date to prevent problems with time zones and DST
				  currentDate.setUTCDate(currentDate.getUTCDate() + 1);
				}
			  
				return dateArray;
			}
			const range = dateRange({startDate, endDate});
	
			range.forEach((date) => {
				if(!(acc as any)[date]) {
					(acc as any)[date] = [];
				}
				(acc as any)[date].push({
					title: doc.title,
					id: doc.id
				});
			});
	
			return acc;
		}, {} as { [key: string]: { title: string; id: string | number }[] });
	
		(acc as { [key: string | number]: FormattedDocuments })[userId] = documents;
		return acc;
	}, {} as { [key: string | number]: FormattedDocuments });

	const weeksArray = data?.map((crewMemberData: {type: string,start_date: string,duration: number}[], index: number) => {
		const rotations = rotationsDatesRanges?.[index]?.rotations;
		if(!rotations) return;

		const isPartner = index === 1 ? true : false; // for now rotation for partner always begins with offboard, if it changes then this should always return false

		const values = calculateRotationWeekNumber({ rotation, page, data: crewMemberData, rotationsDatesRanges: rotations, isPartner, rotationPeriodType });
		
		return values;
	});

	const getWeekDataForIndex = (index: number) => {
		const weekData = weeksArray.map( week => {
			if(!week) return;
			return week[index];
		});
		
		return weekData;
	}

	const generatedCells = data?.[0]?.map((d: {start_date: Date, type: string, duration: number }) => {
		const elements = [];
		const collapsibleElements = [];

		const formattedDate = new Date(d.start_date);

		const { duration: periodDuration } = d;

		const firstDayOfPeriod = formattedDate.getDay() || 7; // set Sunday (0) to 7
		const daysInFirstWeekWithinPeriod = DAYS_IN_WEEK - firstDayOfPeriod + 1;

		const daysVisibleAtFirst =
			daysInFirstWeekWithinPeriod < DAYS_IN_WEEK
				? daysInFirstWeekWithinPeriod + DAYS_IN_WEEK
				: daysInFirstWeekWithinPeriod;
		const daysNotVisibleAtFirst = periodDuration - daysVisibleAtFirst;
		const fullWeeksToWrap = Math.floor(daysNotVisibleAtFirst / DAYS_IN_WEEK);
		const daysToWrap = fullWeeksToWrap * DAYS_IN_WEEK;

		const shouldWrap = daysToWrap > 0;

		for (let index = 0; index < periodDuration; index++) {
			const cellDate = addDays(formattedDate, index);
			const weekYear = getWeek(cellDate, {
				weekStartsOn: 1,
			});
			const today = new Date();

			const formattedCellDate = format(cellDate, "yyyy-MM-dd");
			const isSelected = (changeDayStartDate === formattedCellDate || endDate === formattedCellDate);

			// TODO: check if cell is today
			const cellClasses = cn(
				isSelected ? "bg-orange-500" : hoveredCells?.includes(formattedCellDate) ? "bg-orange-200" : cellColor,
				format(today, "yyyy-MM-dd") === formattedCellDate ? "border-red-500 calendar-current-day" : ""
			);
			
			if (shouldWrap) {
				if (index >= daysVisibleAtFirst && index <= daysVisibleAtFirst + daysToWrap - 1) {
					if (daysGenerated % DAYS_IN_WEEK === 0) {
						collapsibleElements.push(
							<LabelCells crewData={crewData} firstStartDate={firstStartDate} daysGenerated={daysGenerated} weekType={ getWeekDataForIndex(Math.floor(daysGenerated / 7)) }/>,
						);
					}

					collapsibleElements.push(<Cell onClickDocument={openDocumentModal} documents={formattedDocuments} setHoveredCell={setHoveredCell} className={cellClasses} rotationsInfo={rotationsInfo} crewData={datesObject[formattedCellDate]} date={cellDate} weekYear={weekYear} clickHandler={dayClickHandler}/>);
				}

				if (index < daysVisibleAtFirst || index > daysVisibleAtFirst + daysToWrap - 1) {
					if (daysGenerated % DAYS_IN_WEEK === 0) {
						elements.push(
							<LabelCells crewData={crewData} firstStartDate={firstStartDate} daysGenerated={daysGenerated} weekType={getWeekDataForIndex(Math.floor(daysGenerated / 7))}/>,
						);
					}

					elements.push(<Cell onClickDocument={openDocumentModal} documents={formattedDocuments} setHoveredCell={setHoveredCell} className={ cellClasses } rotationsInfo={rotationsInfo} crewData={datesObject[formattedCellDate]} date={cellDate} weekYear={weekYear} clickHandler={dayClickHandler}/>);
				}

				if (index === daysVisibleAtFirst + daysToWrap - 1) {
					elements.push(<Collapsible>{collapsibleElements}</Collapsible>);
				}

				daysGenerated++;

				continue;
			}

			if (daysGenerated % DAYS_IN_WEEK === 0) {
				elements.push(<LabelCells crewData={crewData} firstStartDate={firstStartDate} daysGenerated={daysGenerated} weekType={getWeekDataForIndex(Math.floor(daysGenerated / 7))}/>);
			}

			elements.push(<Cell onClickDocument={openDocumentModal} documents={formattedDocuments} setHoveredCell={setHoveredCell} className={ cellClasses } rotationsInfo={rotationsInfo} crewData={datesObject[formattedCellDate]} date={cellDate} weekYear={weekYear} clickHandler={dayClickHandler}/>);

			daysGenerated++;
		}

		return elements;
	});

	return generatedCells;
};
