/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import axios from "axios";

import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/components/ui/button";
import { WhiteBox } from "@/components/WhiteBox";
import { Textarea } from "@/components/ui/textarea";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Calendar as CalendarIcon, Loader } from "lucide-react";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import { type DateRange } from "react-day-picker";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
	useGetCrewMemberStatisticsQuery
} from "@/app/api/slices/positionsApiSlice";

import { Statistics } from "@/components/calendar/Controls/Statistics";

const BASE_URL = import.meta.env.VITE_API_URL;

interface Props {
    selectedCrewMemberId: string | number | undefined;
}

export const Controls = ({
    selectedCrewMemberId
} : Props) => {
    const [remarks, setRemarks] = useState<string | undefined>();
    const [range, setRange] = useState<DateRange | undefined>();
    const [isDownloadingReport, setIsDownloadingReport] = useState(false);

    const { toast } = useToast();
	const auth = useAuth();
	const vessel_id = auth?.user?.vessel?.id;
    const leaveBalaceType = auth?.user?.vessel?.leave_balance_type;

	const formatDateForStats = (date: Date | undefined) => {
		if(!date) return;
		return format(date, "y-MM-dd");
	}

	const { 
		data: statisticsResponse, 
		isLoading: isLoadingStatistics,
        isFetching: isFetchingStatistics
	} = useGetCrewMemberStatisticsQuery({ 
        vessel_id, 
        user_id: selectedCrewMemberId, 
        startDate: formatDateForStats(range?.from), 
        endDate: formatDateForStats(range?.to) }, 
        { skip: !vessel_id || !selectedCrewMemberId || !range?.from || !range?.to || !selectedCrewMemberId}
    );
	
	const downloadFileFromServer = async ({
		vessel_id,
		user_id,
		start_date,
		end_date,
	}: {
		vessel_id: string;
		user_id: string;
		start_date: string;
		end_date: string;
	}) => {
		setIsDownloadingReport(true);
        try {
            const response = await axios.request({
                method: "POST",
                url: `${BASE_URL}/api/vessels/${vessel_id}/users/${user_id}/report?start_date=${start_date}&end_date=${end_date}`,
                data: {
                    remarks,
                },
                responseType: "blob",
                withCredentials: true,
            });

            if (response.data) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "report.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setRemarks("");
            }
        }
        catch(error: any) {
            const errorResponse = error;
            handleServerErrors({
                data: {
                    message: errorResponse.message,
                    errors: null
                }
            }, toast);
        }
        finally {
		    setIsDownloadingReport(false);
        }
	};

	const downloadReportHandler = () => {
		if (!selectedCrewMemberId || !range || !range.from || !range.to || !vessel_id) return;
		downloadFileFromServer({
			vessel_id: String(vessel_id),
			user_id: String(selectedCrewMemberId),
			start_date: format(range.from, "y-MM-dd"),
			end_date: format(range.to, "y-MM-dd"),
		});
	};

	return (
		<>
            <WhiteBox className={cn("relative ms-4 relative")}>
                { (isFetchingStatistics) && (
                    <div className="backdrop-blur-[2px] m-2 flex justify-center items-center grow absolute top-0 bottom-0 start-0 end-0" >
                        <Loader className="animate-spin duration-2000" color="#000" />
                    </div>
                )}

                <div>
                    <span className="font-bold text-nowrap">Statistics for selected crew member</span>
                </div>
                <hr className="my-1 mb-2" />

                {!selectedCrewMemberId && (
                    <div className="mb-2 text-center text-sm">Select a crew member</div>
                )}

                {selectedCrewMemberId && (
                    <>
                        <div className="mb-2">
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-full justify-start text-left font-normal",
                                            !range && "text-muted-foreground",
                                        )}
                                    >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {range && range.from && range.to ? (
                                            <>
                                                {format(range.from, "y-MM-dd")} - {format(range.to, "y-MM-dd")}
                                            </>
                                        ) : (
                                            <span>Select date</span>
                                        )}
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-auto p-0">
                                    <Calendar 
                                        mode="range" 
                                        selected={range} 
                                        onSelect={setRange}
                                        defaultMonth={range?.from || new Date()}
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </>
                )}

                {/* { (isLoadingStatistics) && (
                    <div className="flex justify-center items-center grow mb-3" >
                        <Loader className="animate-spin duration-2000" color="#000" />
                    </div>
                )} */}

                { (!isLoadingStatistics && selectedCrewMemberId && range?.from && range?.to) && (
                    <div className="flex flex-col mb-3">
                        <Statistics 
                            type="positions"
                            number_of_rotations={statisticsResponse?.data?.estimated_number_of_rotations}
                            number_of_travel_days={statisticsResponse?.data?.estimated_number_of_travel_days}
                            number_of_days_onboard={statisticsResponse?.data?.estimated_number_of_days_onboard}
                            number_of_days_leave={statisticsResponse?.data?.estimated_number_of_days_leave}
                            rotationPeriodType={statisticsResponse?.data?.rotationPeriodType || ""}
                            leaveBalaceType={leaveBalaceType}
                            leave_balance_1_onboard={statisticsResponse?.data?.leave_balance_1_onboard}
                            leave_balance_1_offboard={statisticsResponse?.data?.leave_balance_1_offboard}
                            leave_balance_2={statisticsResponse?.data?.leave_balance_2}
                            leaveAllowance={statisticsResponse?.data?.leaveAllowance}
                            leaveAllowanceBalance={statisticsResponse?.data?.leaveAllowanceBalance}
                        />
                    </div>
                )}

                <div>
                    <Textarea
                        className="resize-none"
                        placeholder="Remarks"
                        disabled={!selectedCrewMemberId}
                        onChange={(e) => setRemarks(e.target.value)}
                        value={remarks}
                    />
                </div>

                <Button
                    className="mt-3 w-full"
                    disabled={!selectedCrewMemberId || isDownloadingReport}
                    onClick={downloadReportHandler}
                >
                    <ButtonLoader isLoading={isDownloadingReport} />
                    Download Report
                </Button>
            </WhiteBox>
		</>
	);
};
