import { useEffect, useState } from "react";

import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import {
	useGetDepartmentPositionsQuery,
	useGetPositionDataQuery
} from "@/app/api/slices/positionsApiSlice";
import { PositionCalendarDayKindDialog } from "@/components/dialogs/PositionCalendarDayKindDialog";

import { useAuth } from "@/hooks/useAuth";

import { WhiteBox } from "@/components/WhiteBox";
import { Calendar as PositionsCalendar } from "@/components/positions-calendar/Calendar";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from "@/components/ui/dialog";

import { joyride_redirect_1_index } from "@/hocs/JoyrideWrapper";
import { useJoyride } from "@/contexts/JoyrideContext";
import { Controls } from "@/components/positions-calendar/Controls";
import { type DepartmentInterface } from "@/types/Calendar";
import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";
import { type DayKindDialogHandler, type CrewDataInterface } from "@/components/calendar/DayKindDialog/types";

export const PositionsPage = () => {
	const { data: configData } = useGetAppConfigQuery({});
	const auth = useAuth();
	const vessel_id = auth?.user?.vessel?.id;
	const [departmentId, setDepartmentId] = useState<string>();

	const [showSettingsMobile, setShowSettingsMobile] = useState(false);

	const [selectedContractId, setSelectedContractId] = useState<string | number | undefined>();
	const [selectedPositionId, setSelectedPositionId] = useState<string | number | undefined>();

	const [perPage, setPerPage] = useState(5);
	const [page, setPage] = useState<number | undefined>();

	const { data: departmentsResponse, isLoading: isLoadingDepartments } =
		useGetDepartmentsAndPositionsQuery({ vesselId: vessel_id }, { skip: !vessel_id });

	const { data: positionsResponse, isLoading: isLoadingPositions } = useGetDepartmentPositionsQuery(
		{ vessel_id: vessel_id, department_id: departmentId },
		{ skip: !vessel_id || !departmentId },
	);

	const { 
		data: positionCalendarResponse, 
		isLoading: isLoadingPositionCalendar,
		isFetching: isFetchingPositionCalendar,
	} = useGetPositionDataQuery(
		{
			vessel_id: vessel_id,
			department_id: departmentId,
			position_id: selectedPositionId,
			contract_id: selectedContractId,
			page: page, 
			rotations_count: perPage,
		},
		{ skip: !vessel_id || !departmentId || !selectedPositionId || !selectedContractId || !page || !perPage},
	);
	const maxPages = positionCalendarResponse?.total_pages || 1;

	const perPageHandler = (value: string) => {
		if (!value || !vessel_id) return;
		const rowsCount = value;

		setPerPage(Number(rowsCount));

		const currentPage = 1;
		setPage(currentPage);
	};

	const pageHandler = ({dir, currentPage}: {dir: string, currentPage: number | undefined}) => {
        if(dir !== "prev" && dir !== "next") throw new Error("Wrong argument!");
		if(currentPage === undefined) return;

        let nextPage = currentPage;
        if(dir === "prev" && currentPage > 1) {
            nextPage -= 1;
            setPage(nextPage);
        } else if(dir === "next" && currentPage < maxPages) {
            nextPage += 1;
            setPage(nextPage);
        }
    };
	
	const [showDayKindModal, setShowDayKindModal] = useState(false);
	const [dayKindStartDate, setDayKindStartDate] = useState<Date>();
	const [crewData, setCrewData] = useState<CrewDataInterface[]>();

	const showDayKindModalHandler: DayKindDialogHandler = ({ startDate, crewData }) => {
		setDayKindStartDate(startDate);
		setShowDayKindModal(true);
		setCrewData(crewData);
	};

	const closeDayKindModalHandler = () => {
		setShowDayKindModal(false);
		setDayKindStartDate(undefined);
		setCrewData(undefined);
	};
	
	// JOYRIDE STARTER
	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && !isLoadingDepartments && !isLoadingPositions && stepIndex === joyride_redirect_1_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoadingDepartments, isLoadingPositions]);
	// END JOYRIDE STARTER

	return (
		<div id="position-calendar-page" className="flex flex-col lg:flex-row">
			<PositionCalendarDayKindDialog
				closeDayKindModalHandler={closeDayKindModalHandler}
				showDayKindModal={showDayKindModal}
				vessel_id={vessel_id || ""}
				department_id={departmentId || ""}
				position_id={selectedPositionId || ""}
				selectedContractId={selectedContractId}
				dayKindStartDate={dayKindStartDate}
				setDayKindStartDate={setDayKindStartDate}
				day_types={configData?.data?.day_types}
				crewMembers={positionCalendarResponse?.crew}
				crewData={crewData}
			/>

			<Dialog open={showSettingsMobile} onOpenChange={() => setShowSettingsMobile(false)} defaultOpen={false}>
				<DialogContent style={{height: "calc(95vh)"}}>
					<DialogHeader>
						<DialogTitle></DialogTitle>
					</DialogHeader>
					<div className="overflow-y-auto">
						<div>
							<Controls 
								isMobile={true}
								setPage={setPage}
								setSelectedContractId={setSelectedContractId}
								setSelectedPositionId={setSelectedPositionId}
    							selectedPositionId={selectedPositionId}
								departmentId={departmentId}
								setDepartmentId={setDepartmentId}
								selectedContractId={selectedContractId}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>

			<div className="block lg:hidden">
				<div className="cursor-pointer" onClick={() => setShowSettingsMobile(true)}>
					<WhiteBox className="lg:w-80 lg:me-4 mb-4 flex justify-between flex-wrap">
						<div className="text-xs me-3">
							<span className="me-1 font-bold">
								{departmentsResponse?.data?.find( (department:{id:number,name:string}) => String(department.id) === String(departmentId))?.name}
							</span>
							<span>
								({positionsResponse?.data?.positions?.find( (position: DepartmentInterface) => String(position.id) === String(selectedPositionId))?.name})
							</span>
						</div>
					</WhiteBox>
				</div>
			</div>

			<section className="hidden lg:block w-80">
				<Controls 
					isMobile={false}
					setPage={setPage}
					setSelectedContractId={setSelectedContractId}
					setSelectedPositionId={setSelectedPositionId}
					selectedPositionId={selectedPositionId}
					departmentId={departmentId}
					setDepartmentId={setDepartmentId}
					selectedContractId={selectedContractId}
				/>
			</section>

			<PositionsCalendar
				page={page}
				perPage={perPage}
				maxPages={maxPages}
				isLoading={isLoadingPositionCalendar || isFetchingPositionCalendar}
				pageHandler={pageHandler}
				perPageHandler={perPageHandler}
				calendarDataGroup={positionCalendarResponse?.crew}
				showDayKindModalHandler={showDayKindModalHandler}
				rotation={positionCalendarResponse?.rotation}
				vesselId={vessel_id}
				departmentId={departmentId}
				positionId={selectedPositionId}
				contractId={selectedContractId}
				rotationPeriodType={positionCalendarResponse?.rotationPeriodType}
			/>
		</div>
	);
};
