/* eslint-disable @typescript-eslint/no-explicit-any */
import { addDays, getWeek, format } from "date-fns";
import { Cell } from "@/components/rotation-calendar/Cell";
import { Collapsible } from "@/components/rotation-calendar/Collapsible";
import { LabelCells } from "@/components/rotation-calendar/LabelCells";
import { DAYS_IN_WEEK, calculateRotationWeekNumber } from "@/utils/calendarUtils";
import { type RotationDaysInterface } from "@/types/Calendar";
import { cn } from "@/lib/utils";

interface document {
	start_date: string;
	end_date: string | null;
	description: string;
	title: string;
	id: string | number;
	files: {
		name: string;
		url: string;
		uploadedBy: string;
	}[];
}


type Props = {
	isPartner: undefined | boolean, 
	page: number | undefined, 
	data: any[], 
	rotationsDatesRanges: undefined | RotationDaysInterface[],
	showDayKindModalHandler: ({startDate, dayKind}:{startDate: Date, dayKind: string}) => void, 
	rotation: string | undefined | null,
	rotationPeriodType: string | undefined,

	documents: null | undefined | document[],
	openDocumentModal: (id: string | number) => void
}

export const generateCells = ({
	isPartner, 
	page, 
	data, 
	rotationsDatesRanges, 
	showDayKindModalHandler, 
	rotation,
	rotationPeriodType,

	documents,
	openDocumentModal
} : Props) => {
	if(!data || data.length === 0) return null;

	const firstStartDate = data[0].start_date;
	let daysGenerated = 0;

	const weeksArray = calculateRotationWeekNumber({ rotation, page, data, rotationsDatesRanges, isPartner, rotationPeriodType });

	const formattedDocuments: undefined | object | { [key: string]: any[] } = documents?.reduce((acc, doc) => {
		const startDate = doc.start_date;
		const endDate = doc.end_date || startDate;
		function dateRange({startDate, endDate}: {startDate: string, endDate: string}) {
			const dateArray = [];
			const currentDate = new Date(startDate);
		  
			while (currentDate <= new Date(endDate)) {
			  dateArray.push(format(currentDate, "y-MM-dd"));
			  // Use UTC date to prevent problems with time zones and DST
			  currentDate.setUTCDate(currentDate.getUTCDate() + 1);
			}
		  
			return dateArray;
		}
		const range = dateRange({startDate, endDate});

		range.forEach((date) => {
			if(!(acc as any)[date]) {
				(acc as any)[date] = [];
			}
			(acc as any)[date].push({
				title: doc.title,
				id: doc.id
			});
		});

		return acc;
	}, {});

	const retData = data.map((d) => {
		const elements = [];
		const collapsibleElements = [];

		const formattedDate = new Date(d.start_date);

		const { type: periodType, duration: periodDuration } = d;

		const firstDayOfPeriod = formattedDate.getDay() || 7; // set Sunday (0) to 7
		const daysInFirstWeekWithinPeriod = DAYS_IN_WEEK - firstDayOfPeriod + 1;

		const daysVisibleAtFirst =
			daysInFirstWeekWithinPeriod < DAYS_IN_WEEK
				? daysInFirstWeekWithinPeriod + DAYS_IN_WEEK
				: daysInFirstWeekWithinPeriod;
		const daysNotVisibleAtFirst = periodDuration - daysVisibleAtFirst;
		const fullWeeksToWrap = Math.floor(daysNotVisibleAtFirst / DAYS_IN_WEEK);
		const daysToWrap = fullWeeksToWrap * DAYS_IN_WEEK;

		const shouldWrap = daysToWrap > 0;

		for (let index = 0; index < periodDuration; index++) {
			const cellDate = addDays(formattedDate, index);
			const weekYear = getWeek(cellDate, {
				weekStartsOn: 1,
			});

			const formattedCellDate = format(cellDate, "yyyy-MM-dd");
			const today = new Date();
			const cellClasses = cn(
				format(today, "yyyy-MM-dd") === formattedCellDate ? "border-red-500 calendar-current-day" : ""
			);

			if (shouldWrap) {
				if (index >= daysVisibleAtFirst && index <= daysVisibleAtFirst + daysToWrap - 1) {
					if (daysGenerated % DAYS_IN_WEEK === 0) {
						collapsibleElements.push(
							<LabelCells firstStartDate={firstStartDate} daysGenerated={daysGenerated} weekType={weeksArray[Math.floor(daysGenerated / 7)]}/>,
						);
					}

					collapsibleElements.push(<Cell className={ cellClasses } onClickDocument={openDocumentModal} documents={formattedDocuments} type={periodType} date={cellDate} weekYear={weekYear} clickHandler={showDayKindModalHandler}/>);
				}

				if (index < daysVisibleAtFirst || index > daysVisibleAtFirst + daysToWrap - 1) {
					if (daysGenerated % DAYS_IN_WEEK === 0) {
						elements.push(
							<LabelCells firstStartDate={firstStartDate} daysGenerated={daysGenerated} weekType={weeksArray[Math.floor(daysGenerated / 7)]}/>,
						);
					}

					elements.push(<Cell className={ cellClasses } onClickDocument={openDocumentModal} documents={formattedDocuments} type={periodType} date={cellDate} weekYear={weekYear} clickHandler={showDayKindModalHandler}/>);
				}

				if (index === daysVisibleAtFirst + daysToWrap - 1) {
					elements.push(<Collapsible>{collapsibleElements}</Collapsible>);
				}

				daysGenerated++;

				continue;
			}

			if (daysGenerated % DAYS_IN_WEEK === 0) {
				elements.push(<LabelCells firstStartDate={firstStartDate} daysGenerated={daysGenerated} weekType={weeksArray[Math.floor(daysGenerated / 7)]}/>);
			}

			elements.push(<Cell className={ cellClasses } onClickDocument={openDocumentModal} documents={formattedDocuments} type={periodType} date={cellDate} weekYear={weekYear} clickHandler={showDayKindModalHandler}/>);

			daysGenerated++;
		}

		return elements;
	});

	return retData;
};
