/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { type ReactNode, useState, useMemo, useEffect } from "react";

import { WhiteBox } from "@/components/WhiteBox";
import { Button } from "@/components/ui/button";
import * as Tooltip from '@radix-ui/react-tooltip';
import { cn } from "@/lib/utils";
import { format, isToday } from "date-fns";

import * as Checkbox from '@radix-ui/react-checkbox';

import { getClassFromType, DayType } from "@/utils/calendarUtils";
import RotatLogo from "@/assets/logo-small.svg";
import { ButtonLoader } from "@/components/ButtonLoader";
import { type OpenStates } from "@/types/AccordionOpenStates";
import { ChevronsUpDown, CheckIcon } from "lucide-react";

import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";

import { RotationCalendarDayKindDialog } from "../dialogs/RotationCalendarDayKindDialog";

import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogClose
} from "@/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

type CalendarProps = {
	selectedCrewMemberId: string | number | undefined;
	setSelectedCrewMemberId: React.Dispatch<React.SetStateAction<string | number | undefined>>;
};

export const Calendar = ({
	selectedCrewMemberId,
	setSelectedCrewMemberId
}: CalendarProps) => {
	const [showYearDialog, setShowYearDialog] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState<string | undefined | Group[]>('all');

	const [onOffView, _setOnOffView] = useState<OnOffView>('all');

	const [selectedYear, setSelectedYear] = useState<string | undefined>("2024");
	const [selectedMonth, setSelectedMonth] = useState<number | undefined>(1);
	const [daysInMonth, setDaysInMonth] = useState<number | undefined>(31);

	const showFiltersDialogHandler = () => {
		setShowFilters(true);
	}

	const hideFiltersDialogHandler = () => {
		setShowFilters(false);
	}

	const selectedYearHandler = (year: string | undefined) => {
		setSelectedYear(year);
	}

	const showYearDialogHandler = () => {
		setShowYearDialog(true);
	}

	const hideYearDialogHandler = () => {
		setShowYearDialog(false);
	}

	function getDaysInMonth(month: number | string, year: number | string) {
		const monthNum = Number(month);
		const yearNum = Number(year);


		const daysInMonths = [31, (yearNum % 4 === 0 && (yearNum % 100 !== 0 || yearNum % 400 === 0)) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		return daysInMonths[monthNum - 1];
	}

	const selectMonthHandler = (month: number) => {
		if(!month || !selectedYear) return;

		setSelectedMonth(month);
		setDaysInMonth(getDaysInMonth(month, selectedYear));
	}

	const departmentsAndPositionsData = useMemo(() => ([
		{
			id: 1,
			name: "Deck",
			positions: [
				{
					id: 1,
					name: "Captain",
				},
				{
					id: 2,
					name: "C/O",
				}
			]
		},
		{
			id: 2,
			name: "Engineer",
			positions: [
				{
					id: 4,
					name: "C/E",
				}
			]
		},
		{
			id: 3,
			name: "Purser",
			positions: [
				{
					id: 5,
					name: "Purser",
				}
			]
		},
		{
			id: 4,
			name: "Interior",
			positions: [
				{
					id: 6,
					name: "C/S",
				}
			]
		},
	]), []);
	const [selectedDepartmentsAndPositions, setSelectedDepartmentsAndPositions] = useState<Group[]>([]);

	const selectedDepartmentsAndPositionsHandler = (selected: Group[]) => {
		setSelectedDepartmentsAndPositions(selected);
		setSelectedGroups('custom');
	}

	useEffect(() => {
		setSelectedDepartmentsAndPositions(departmentsAndPositionsData.map( department => ({
			department: department.id,
			positions: department.positions.map( position => position.id)
		})));
	}, [departmentsAndPositionsData]);


	const crewData = useMemo(() => ({
		departments: [
			{
				id: 1,
				name: "Deck",
				positions: [
					{ 
						id: 1, 
						name: "Captain",
						crew: [
							{
								isHOD: true,
								id: 1,
								name: "John Doe",
								calendar: [
									{
										duration: 1,
										end_date: "2024-01-01",
										start_date: "2024-01-01",
										type: "travel_day_change_day"
									},
									{
										duration: 12,
										end_date: "2024-01-02",
										start_date: "2024-01-13",
										type: "onboard_day"
									},
									{
										duration: 1,
										end_date: "2024-01-14",
										start_date: "2024-01-14",
										type: "travel_day_change_day"
									},
									{
										duration: 12,
										end_date: "2024-01-15",
										start_date: "2024-01-28",
										type: "rotational_leave"
									},
									{
										duration: 1,
										end_date: "2024-01-29",
										start_date: "2024-01-29",
										type: "travel_day_change_day"
									},
									{
										duration: 4,
										end_date: "2024-01-30",
										start_date: "2024-01-31",
										type: "onboard_day"
									},
								]
							},
							{
								isHOD: false,
								id: 2,
								name: "Wilbert Abenrnthy",
								calendar: [
									{
										duration: 1,
										end_date: "2024-01-01",
										start_date: "2024-01-01",
										type: "travel_day_change_day"
									},
									{
										duration: 12,
										end_date: "2024-01-02",
										start_date: "2024-01-13",
										type: "rotational_leave"
									},
									{
										duration: 1,
										end_date: "2024-01-14",
										start_date: "2024-01-14",
										type: "travel_day_change_day"
									},
									{
										duration: 12,
										end_date: "2024-01-15",
										start_date: "2024-01-28",
										type: "onboard_day"
									},
									{
										duration: 1,
										end_date: "2024-01-29",
										start_date: "2024-01-29",
										type: "travel_day_change_day"
									},
									{
										duration: 4,
										end_date: "2024-01-30",
										start_date: "2024-01-31",
										type: "rotational_leave"
									},
								]
							}
						],
					},
					{ 
						id: 2, 
						name: "C/O",
						crew: [
							{
								isHOD: false,
								id: 3,
								name: "Dorris Bashiron",
								calendar: [
									{
										duration: 1,
										end_date: "2024-01-01",
										start_date: "2024-01-01",
										type: "travel_day_change_day"
									},
									{
										duration: 19,
										end_date: "2024-01-02",
										start_date: "2024-01-13",
										type: "onboard_day"
									},
									{
										duration: 1,
										end_date: "2024-01-21",
										start_date: "2024-01-21",
										type: "travel_day_change_day"
									},
									{
										duration: 10,
										end_date: "2024-01-22",
										start_date: "2024-01-31",
										type: "rotational_leave"
									}
								]
							},
							{
								isHOD: false,
								id: 4,
								name: "Lela Bode",
								calendar: [
									{
										duration: 1,
										end_date: "2024-01-01",
										start_date: "2024-01-01",
										type: "travel_day_change_day"
									},
									{
										duration: 19,
										end_date: "2024-01-02",
										start_date: "2024-01-13",
										type: "rotational_leave"
									},
									{
										duration: 1,
										end_date: "2024-01-21",
										start_date: "2024-01-21",
										type: "travel_day_change_day"
									},
									{
										duration: 10,
										end_date: "2024-01-22",
										start_date: "2024-01-31",
										type: "onboard_day"
									}
								]
							}
						],
					}
				],
			},
			{
				id: 2,
				name: "Engineer",
				positions: [{ 
					id: 4, 
					name: "C/E",
					crew: [
						{
							isHOD: true,
							id: 5,
							name: "Ava Christiansen",
							calendar: [
								{
									duration: 12,
									end_date: "2024-01-02",
									start_date: "2024-01-13",
									type: "onboard_day"
								},
								{
									duration: 1,
									end_date: "2024-01-14",
									start_date: "2024-01-14",
									type: "travel_day_change_day"
								},
								{
									duration: 2,
									end_date: "2024-01-15",
									start_date: "2024-01-16",
									type: "travel_day"
								},
								{
									duration: 10,
									end_date: "2024-01-16",
									start_date: "2024-01-28",
									type: "rotational_leave"
								},
								{
									duration: 1,
									end_date: "2024-01-29",
									start_date: "2024-01-29",
									type: "travel_day_change_day"
								},
								{
									duration: 5,
									end_date: "2024-01-30",
									start_date: "2024-01-31",
									type: "onboard_day"
								},
							]
						},
						{
							isHOD: false,
							id: 6,
							name: "Matilde Hoeger",
							calendar: [
								{
									duration: 12,
									end_date: "2024-01-02",
									start_date: "2024-01-13",
									type: "rotational_leave"
								},
								{
									duration: 1,
									end_date: "2024-01-14",
									start_date: "2024-01-14",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-15",
									start_date: "2024-01-28",
									type: "onboard_day"
								},
								{
									duration: 1,
									end_date: "2024-01-29",
									start_date: "2024-01-29",
									type: "travel_day_change_day"
								},
								{
									duration: 5,
									end_date: "2024-01-30",
									start_date: "2024-01-31",
									type: "rotational_leave"
								},
							]
						}
					],
				}],
			},
			{
				id: 3,
				name: "Purser",
				positions: [{ 
					id: 5, 
					name: "Purser",
					crew: [
						{
							isHOD: true,
							id: 7,
							name: "Blade Cummerata",
							calendar: [
								{
									duration: 1,
									end_date: "2024-01-01",
									start_date: "2024-01-01",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-02",
									start_date: "2024-01-13",
									type: "onboard_day"
								},
								{
									duration: 1,
									end_date: "2024-01-14",
									start_date: "2024-01-14",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-15",
									start_date: "2024-01-28",
									type: "rotational_leave"
								},
								{
									duration: 1,
									end_date: "2024-01-29",
									start_date: "2024-01-29",
									type: "travel_day_change_day"
								},
								{
									duration: 4,
									end_date: "2024-01-30",
									start_date: "2024-01-31",
									type: "onboard_day"
								},
							]
						}
					],
				}],
			},
			{
				id: 4,
				name: "Interior",
				positions: [{ 
					id: 6, 
					name: "C/S",
					crew: [
						{
							isHOD: false,
							id: 8,
							name: "Tomasa Bergstrom",
							calendar: [
								{
									duration: 1,
									end_date: "2024-01-01",
									start_date: "2024-01-01",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-02",
									start_date: "2024-01-13",
									type: "onboard_day"
								},
								{
									duration: 1,
									end_date: "2024-01-14",
									start_date: "2024-01-14",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-15",
									start_date: "2024-01-28",
									type: "rotational_leave"
								},
								{
									duration: 1,
									end_date: "2024-01-29",
									start_date: "2024-01-29",
									type: "travel_day_change_day"
								},
								{
									duration: 4,
									end_date: "2024-01-30",
									start_date: "2024-01-31",
									type: "onboard_day"
								},
							]
						},
						{
							isHOD: true,
							id: 9,
							name: "Davin Roob",
							calendar: [
								{
									duration: 1,
									end_date: "2024-01-01",
									start_date: "2024-01-01",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-02",
									start_date: "2024-01-13",
									type: "rotational_leave"
								},
								{
									duration: 1,
									end_date: "2024-01-14",
									start_date: "2024-01-14",
									type: "travel_day_change_day"
								},
								{
									duration: 12,
									end_date: "2024-01-15",
									start_date: "2024-01-28",
									type: "onboard_day"
								},
								{
									duration: 1,
									end_date: "2024-01-29",
									start_date: "2024-01-29",
									type: "travel_day_change_day"
								},
								{
									duration: 4,
									end_date: "2024-01-30",
									start_date: "2024-01-31",
									type: "rotational_leave"
								},
							]
						}
					],
				}],
			}
		]
	}), []);

	const filteredCrewData = useMemo(() => {
		const filteredByDepartments = crewData.departments.filter( department => {
			return selectedDepartmentsAndPositions.some( selected => selected.department === department.id);
		});

		const filteredByPositions = filteredByDepartments.map( department => {
			return {
				...department,
				positions: department.positions.filter( position => {
					const selectedDepartment = selectedDepartmentsAndPositions.find( selected => selected.department === department.id );
					return selectedDepartment?.positions?.includes(position.id) || false;
				})
			}
		});

		return {
			departments: filteredByPositions.map( department => {
				return {
					...department,
					positions: department.positions.map( position => {
						return {
							...position,
							crew: position.crew.filter( crewMember => {
								if(selectedGroups === 'custom') return true;
								if(selectedGroups === 'all') return true;
								if(selectedGroups === 'hods') return crewMember.isHOD;
								// TODO: Add filter for onboard and offboard (on/off view)
								return false;
							})
						}
					})
				}
			})
		}

	}, [selectedGroups, crewData, selectedDepartmentsAndPositions]);


	const [showDayKindModal, setShowDayKindModal] = useState(false);
	const [defaultDayKind, setDefaultDayKind] = useState<string | undefined>();
	const [dayKindStartDate, setDayKindStartDate] = useState<Date | undefined>();

	const openDayKindModalHandler = () => {
		setShowDayKindModal(true);
	}

	const closeDayKindModalHandler = () => {
		setShowDayKindModal(false);
		setDefaultDayKind(undefined);
		setDayKindStartDate(undefined);
	}

	const onClickDayHandler = (day: any) => {
		setDefaultDayKind(day.type);
		setDayKindStartDate(new Date(day.start_date));
		openDayKindModalHandler();
	}

	const generateCells = ({calendarData} : {calendarData: any}) => {
		return calendarData.map((day: any) => {
			return (
				[...Array(day.duration)].map((_, index) => (
					<CalendarCell 
						key={index}
						className={"min-w-12 px-0 " + (getClassFromType(day.type) || "")}
						label={(day.type === DayType.Change) || (day.type === DayType.TravelAndChange) && (
							<img src={RotatLogo} alt="Rotat Logo" className="w-4 h-4 inline-block"/>
						)}
						onClick={() => onClickDayHandler(day)}

						type={day.type}
						documents={undefined}
						onClickDocument={() => {}}
						date={new Date(day.start_date)}
						showTooltip={true}
					/>
				))
			);
		});
	}

	const selectCrewMemberHandler = (crewMember: any) => {
		setSelectedCrewMemberId(crewMember);
	}

	const setSelectedGroupsHandler = (groups: string | undefined | Group[]) => {
		setSelectedGroups(prevState => {
			if(prevState === groups) return 'custom';
			return groups;
		});

		if(['all'].includes(groups)) {
			setSelectedDepartmentsAndPositions(departmentsAndPositionsData.map( department => ({
				department: department.id,
				positions: department.positions.map( position => position.id)
			})));
		}
	}

	// const getDateDayShortName = (day: number) => {
	// 	const date = new Date(selectedYear, selectedMonth - 1, day);
	// 	console.log(date);
	// 	return format(date, "EEE");
	// }

	const monthDaysNames = useMemo(() => {
		if(!daysInMonth || !selectedMonth || !selectedYear) return [];

		return [...Array(daysInMonth)].map((_, index) => {
			const date = new Date(selectedYear, selectedMonth - 1, index + 1);
			console.log(date);
			return format(date, "EEE");
		});
	}, [daysInMonth, selectedMonth, selectedYear]);

	console.log("[Calendar] monthDaysNames: ", monthDaysNames);

	const isCurrentDate = (day: number) => {
		return isToday(new Date(selectedYear, selectedMonth - 1, day));
	}


	return (
		<>
			<SelectYear 
				show={showYearDialog}
				onHide={hideYearDialogHandler}
				selectedYear={selectedYear}
				onSelectYear={selectedYearHandler}
			/>

			<Filters 
				show={showFilters}
				onHide={hideFiltersDialogHandler}
				defaultOnOffView={onOffView}

				selectedDepartmentsAndPositions={selectedDepartmentsAndPositions}
				setSelectedDepartmentsAndPositions={selectedDepartmentsAndPositionsHandler}
			/>

			<RotationCalendarDayKindDialog 
				closeDayKindModalHandler={closeDayKindModalHandler}
				showDayKindModal={showDayKindModal}
				vessel_id={1}
				user_id={1}
				selectedContractId={1}
				setDayKindStartDate={setDayKindStartDate}
				dayKindStartDate={dayKindStartDate}
				defaultDayKind={defaultDayKind}
			/>

			<WhiteBox className="relative flex flex-col min-h-96 grow overflow-y-auto">

				<div className="relative flex flex-col grow py-4">
					
					<div className="flex justify-between">
						<div className="flex">
							<div className="pe-3 w-52">
								<Button className={`w-full h-12 ${ selectedGroups === 'all' ? 'bg-orange-300 hover:bg-orange-400' : '' }`} variant='outline' onClick={() => setSelectedGroupsHandler('all')}>All crew</Button>
							</div>
							<div className="pe-3 w-52">
								<Button className={`w-full h-12 ${ selectedGroups === 'hods' ? 'bg-orange-300' : '' }`} variant='outline' onClick={() => setSelectedGroupsHandler('hods')}>HODs</Button>
							</div>
						</div>

						<div className="grow max-w-52">
							<Button className="w-full h-12 flex" variant='outline' onClick={showFiltersDialogHandler}>
								<span>Filters</span>
							</Button>
						</div>
					</div>

					<hr className="my-3" />

					<div className="flex">
						{/* Labels */}
						<div className="pe-3 w-52">
							<CalendarRowLabel label="Year" />
							<CalendarRowLabel label="Month" />
						</div>

						{/* Data */}
						<div className="grow flex flex-col">
							<div className="mb-3">
								<CalendarCell label="2024" className="" onClick={showYearDialogHandler}/>
							</div>

							<div className="mb-3 flex gap-1">
								{['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
									<CalendarCell 
										key={month}
										onClick={() => selectMonthHandler(index + 1)} 
										label={String(month)} 
										className={ selectedMonth === (index + 1) ? "bg-orange-300" : "" }
									/>
								))}
							</div>
						</div>
					</div>


					<div className="flex">
						{/* Labels */}
						<div className="pe-3 w-52 shrink-0">
							<CalendarRowLabel label="Day" />
							{ filteredCrewData.departments.map( department => (
								<div className="grow" key={department.id}>

									<div className="h-3.5 text-sm text-slate-400 leading-none">{department.name}</div>
									<hr className="pb-1"/>

									{ department.positions.map( position => (
										<div className="grow" key={position.id}>
											<div className="mb-3">
												{ position.crew.map( crewMember => (
													<CalendarRowLabel 
														key={crewMember.id} 
														label={(
															<div className="flex flex-col">
																<div className="leading-none mb-[2px]">{crewMember.name}</div>
																<div className="text-xs font-normal leading-none">{position.name}</div>
															</div>
														)} 
														isClickable={true}
														onClick={() => selectCrewMemberHandler(crewMember.id)}
														className={selectedCrewMemberId === crewMember.id ? "bg-orange-300" : "bg-slate-100"}
													/>
												))}
											</div>
										</div>
									))}
								</div>
							))}
						</div>

						{/* Data */}
						<div className="flex flex-col overflow-y-auto grow">
							
							<div className="mb-3 flex shrink-0 gap-1">
								{[...Array(daysInMonth)].map((_, index) => (
									<CalendarCell 
										key={index}
										label={(
											<div className="flex flex-col justify-center items-center">
												<div>{ index + 1 }</div>
												<div className="text-xs">{ monthDaysNames?.[index] || "" }</div>
											</div>
										)}
										className={cn('px-0', isCurrentDate(index+1) && 'calendar-current-day')}
									/>
								))}
							</div>

							{ filteredCrewData.departments.map( department => (
								<div className="grow" key={department.id}>

									<div className="h-3.5"></div>
									<hr className="pb-1"/>

									{ department.positions.map( position => (
										<div className="grow" key={position.id}>
												{ position.crew.map( crewMember => 
													<div key={crewMember.id} className="mb-3 flex gap-1">
														{generateCells({calendarData: crewMember.calendar})}
													</div>
												)}
										</div>
									))}

								</div>
							))}
						</div>

					</div>
				</div>

			</WhiteBox>
		</>
	);
};


const CalendarRowLabel = ({ 
	label, 
	className="bg-slate-100",

	isClickable=false,
	onClick=()=>{}
}: { 
	label?: ReactNode, 
	className?: string,

	isClickable?: boolean,
	onClick?: () => void;
}) => {
	return (
		<div 
			className={cn(`mb-3 px-3 h-12 border rounded-md flex justify-center items-center text-center text-sm font-medium`, className, isClickable ? 'cursor-pointer' : '')}
			onClick={onClick}
		>
			{label}
		</div>
	);
}

const CalendarCell = ({ 
	label, 
	onClick=()=>{}, 
	className="bg-slate-50",
	type="",
	documents,
	onClickDocument,
	date,
	showTooltip=false
}: { 
	label?: ReactNode, 
	onClick?: () => void, 
	className?: string,
	type?: string,
	documents?: FormattedDocuments[] | undefined | object;
	onClickDocument?: (id: string | number) => void;
	date?: Date; 
	showTooltip?: boolean;
}) => {
	const { data: configData } = useGetAppConfigQuery({});
	const formattedDate = date ? format(date, "y-MM-dd") : "-";

	if(!showTooltip) {
		return (
			<div onClick={onClick} style={{flexBasis: '100%'}} className={`min-w-12 overflow-hidden cursor-pointer grow px-1 h-12 border hover:border-slate-400 duration-300 rounded-md flex justify-center items-center text-center text-sm ${className}`}>
				{label}
			</div>
		)
	}

	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>
					<div onClick={onClick} style={{flexBasis: '100%'}} className={`min-w-12 overflow-hidden cursor-pointer grow px-1 h-12 border hover:border-slate-400 duration-300 rounded-md flex justify-center items-center text-center text-sm ${className}`}>
						{label}
					</div>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content className="TooltipContent" sideOffset={5}>
						{ showTooltip ? (
							<div className="flex flex-col">
								<div className="text-xs lg:text-sm font-medium capitalize">{ configData?.data?.day_types?.[type] }</div>
								<div className="text-xs lg:text-sm">{ formattedDate }</div>

								{ (documents && documents[formattedDate]) && (
									<div className="mt-2">
										<div className="text-xs lg:text-sm font-medium capitalize">Related documents: </div>
								
										{documents[formattedDate].map((doc: {id: string | number, title: string}) => {
											return (
												<div key={doc.id}>
													{/* <a href={doc.url} target="_blank" rel="noreferrer" className="text-xs lg:text-sm text-primary underline">{doc.name}</a> */}
													<p onClick={() => onClickDocument(doc.id)} className="text-xs lg:text-sm text-primary underline cursor-pointer">{doc.title}</p>
												</div>
											);
										})}
								
									</div>
								) }
							</div>
						) : null }
					</Tooltip.Content>
				</Tooltip.Portal>
				</Tooltip.Root>
    	</Tooltip.Provider>
	);
}

type SelectedYearProps = {
	show: boolean;
	onHide: () => void;
	selectedYear: string | undefined;
	onSelectYear: (year: string) => void;
}
const SelectYear = ({
	show,
	onHide,
	selectedYear,
	onSelectYear
}: SelectedYearProps) => {
	const availableYears = [2024]; 

	return (
		<Dialog open={show} onOpenChange={onHide} defaultOpen={false}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Select Year</DialogTitle>
				</DialogHeader>

				<div className="overflow-y-auto">
					<div>
						<Select
							onValueChange={onSelectYear}
							value={selectedYear}
							disabled={false}
						>
							<SelectTrigger className="relative">
								<SelectValue placeholder="Select department..." />
								{false && (
									<div className="absolute inset-0 flex items-center justify-center">
										<ButtonLoader isLoading={true} />
									</div>
								)}
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{availableYears?.map((year: string | number) => (
										<SelectItem key={year} value={String(year)}>
											{year}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
				</div>

				<DialogFooter>
					<div className="flex w-full justify-end">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Close
							</Button>
						</DialogClose>
					</div>
				</DialogFooter>

			</DialogContent>
		</Dialog>
	)
}

type FiltersProps = {
	show: boolean;
	onHide: () => void;
	defaultOnOffView: OnOffView;
	selectedDepartmentsAndPositions: Group[];
	setSelectedDepartmentsAndPositions: React.Dispatch<React.SetStateAction<Group[]>>;
}
const Filters = ({
	show,
	onHide,
	defaultOnOffView,
	selectedDepartmentsAndPositions,
	setSelectedDepartmentsAndPositions
}: FiltersProps) => {
	const [onOffView, setOnOffView] = useState<OnOffView>(defaultOnOffView);
	const [openStates, setOpenStates] = useState<OpenStates>({});

	const [selectedFilters, setSelectedFilters] = useState<Group[]>([]);

	const data = [
		{
			id: 1,
			name: "Deck",
			positions: [
				{
					id: 1,
					name: "Captain",
				},
				{
					id: 2,
					name: "C/O",
				}
			]
		},
		{
			id: 2,
			name: "Engineer",
			positions: [
				{
					id: 4,
					name: "C/E",
				}
			]
		},
		{
			id: 3,
			name: "Purser",
			positions: [
				{
					id: 5,
					name: "Purser",
				}
			]
		},
		{
			id: 4,
			name: "Interior",
			positions: [
				{
					id: 6,
					name: "C/S",
				}
			]
		},
	]

	const onDepartmentChangeHandler = (departmentId: number) => {
		const selectedFilterIndex = selectedFilters.findIndex( filter => filter.department === departmentId);
		if(selectedFilterIndex === -1) {
			setSelectedFilters([...selectedFilters, {department: departmentId, positions: data.find( department => department.id === departmentId)?.positions.map( position => position.id) || []}]);
		} else {
			const updatedFilters = selectedFilters.filter( filter => filter.department !== departmentId);
			setSelectedFilters(updatedFilters);
		}
	}

	const getDepartmentValue = (departmentId: number) => {
		return selectedFilters.findIndex( filter => filter.department === departmentId) !== -1;
	}

	const onPositionChangeHandler = (departmentId: number, positionId: number) => {
		const selectedFilterIndex = selectedFilters.findIndex( filter => filter.department === departmentId);
		if(selectedFilterIndex === -1) {
			setSelectedFilters([...selectedFilters, {department: departmentId, positions: [positionId]}]);
		} else {
			const updatedFilters = selectedFilters.map( filter => {
				if(filter.department === departmentId) {
					if(filter.positions.includes(positionId)) {
						return {
							...filter,
							positions: filter.positions.filter( position => position !== positionId)
						}
					} else {
						return {
							...filter,
							positions: [...filter.positions, positionId]
						}
					}
				}
				return filter;
			}).filter( filter => filter.positions.length > 0);
			setSelectedFilters(updatedFilters);
		}
	}

	const getPositionValue = (departmentId: number, positionId: number) => {
		const department = selectedFilters.find( filter => filter.department === departmentId);

		if(department) {
			return department.positions.includes(positionId);
		}
		return false;
	}

	const onCancelHandler = () => {
		setSelectedFilters(selectedDepartmentsAndPositions);
		onHide();
	}

	const toggleOpenState = (sectionId: number) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

	const onOffViewHandler = (value: OnOffView) => {
		setOnOffView(value);
	}

	const onApplyHandler = () => {
		setSelectedDepartmentsAndPositions(selectedFilters);
		onHide();
	}

	useEffect(() => {
		if(show) {
			setSelectedFilters(selectedDepartmentsAndPositions);
		}
	}, [show, selectedDepartmentsAndPositions]);

	return (
		<Dialog open={show} onOpenChange={onHide} defaultOpen={false}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle></DialogTitle>
				</DialogHeader>

				<div className="overflow-y-auto">
					
						<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2 p-1">
							<div className="flex items-center justify-start gap-2">
								<div className="whitespace-nowrap">On/Off View</div>
							</div>
							<div className="">
								<Select onValueChange={onOffViewHandler} defaultValue={onOffView} disabled={false}>
									<SelectTrigger>
										<SelectValue />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{ [
												{ label: 'Show All Crew', value: 'all' },
												{ label: 'Crew Signed On', value: 'on' },
												{ label: 'Crew Signed Off', value: 'off' }
											].map(({label, value}) => (
												<SelectItem key={label} value={value}>{label}</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>

						<hr className="my-1" />

						<div className="flex items-center justify-start p-1">
							<div className="whitespace-nowrap">Selected department(s) and position(s)</div>
						</div>
						{data?.map((department) => {
								return (
									<Collapsible
										key={department.id}
										open={openStates[department.id] || false}
										onOpenChange={() => toggleOpenState(department.id)}
										className="p-1 pb-0"
									>
										<div className="flex items-center justify-between mb-1">
											<CollapsibleTrigger asChild>
												<Button
													variant="ghost"
													size="sm"
													className={cn("flex w-full items-center justify-between border bg-slate-50")}
												>
													<h4 className="text-sm font-semibold">{department.name}</h4>

													<div className="flex items-center">
														
														<div onClick={(e) => {e.stopPropagation()}}>
															<Checkbox.Root className="w-6 h-6 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
																onCheckedChange={() => onDepartmentChangeHandler(department.id)}
																checked={getDepartmentValue(department.id)}
															>
																<Checkbox.Indicator className="CheckboxIndicator">
																	<CheckIcon />
																</Checkbox.Indicator>
															</Checkbox.Root>
														</div>
														
														<ChevronsUpDown className="h-4 w-4" />
														<span className="sr-only">Toggle</span>
													</div>
												</Button>
											</CollapsibleTrigger>
										</div>
										<CollapsibleContent className="space-y-1 ps-3">
											{(!department?.positions || department?.positions?.length < 1) && (
												<div className="text-center text-sm">
													No positions available for selected department
												</div>
											)}

											{department?.positions?.map((position) => {
												return (
													<div key={`contract-${position.id}`} className="relative flex flex-col ">
														
														<div className="border rounded-md p-1 px-2 flex items-center justify-between">
															<div>{position.name}</div>

															<Checkbox.Root className="w-6 h-6 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
																	onCheckedChange={() => onPositionChangeHandler(department.id, position.id)}
																	checked={getPositionValue(department.id, position.id)}
																>
																	<Checkbox.Indicator className="CheckboxIndicator">
																		<CheckIcon />
																	</Checkbox.Indicator>
															</Checkbox.Root>
														</div>

														
													</div>
												);
											})}
										</CollapsibleContent>
									</Collapsible>
								);
							},
						)}
					<hr className="mt-3" />
				</div>

				<DialogFooter>
					<div className="flex w-full justify-end gap-2">
						<Button type="button" variant="secondary" onClick={onCancelHandler}>
							Cancel
						</Button>
						<Button type="button" onClick={onApplyHandler}>
							Apply
						</Button>
					</div>
				</DialogFooter>

			</DialogContent>
		</Dialog>
	)
}

type OnOffView = 'all' | 'on' | 'off';
interface FormattedDocuments {
	[key: string]: {
		title: string;
		id: string | number;
	};
}
type Group = {
	department: string | number;
	positions: string[] | number[];
}
